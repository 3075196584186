<template>
  <div class="auction__wrapper">
    <div class="auction__inner">
      <div class="auction__price-wrapper">
        <Skeleton v-if="isLoading" :height="'80px'" style="padding: 16px;" />
        <div v-else class="auction__price-inner" :class="{'is-orange': post.postType === 'sold'}">
          <span v-if="post.postType === 'forSale'" class="auction__price-title">Current bid</span>
          <span class="auction__price-number">$ {{ currentBid }}</span>
        </div>
      </div>

      <div v-if="isLoading" class="auction__form-skeleton">
        <Skeleton v-for="i of 2" :key="i" :height="'40px'" />
      </div>
      <form v-if="!wasEdit && !isLoading && post.postType !== 'sold'" class="auction__form" @submit.prevent="placeBid">
        <div class="auction__form-title">Place your bid</div>
        <input v-model.trim="bid" v-mask="'##########'" type="text" class="field__input" placeholder="Your bid price" />
        <button class="button button--fill" type="submit">Place bid</button>
      </form>
      <div v-if="wasEdit && !isLoading" class="auction__buttons">
        <button
          v-if="(post.postType === 'sold' && post.soldFor) || (post.postType === 'forSale' && post.currentBid)"
          class="button button--fill"
          @click="viewBids"
        >
          View Bids Activity
        </button>
        <button v-if="post.postType !== 'sold'" class="button button--orange" @click="sold">Mark as Sold</button>
      </div>

      <div v-if="isLoading" class="auction__bids-skeleton">
        <Skeleton v-for="i of 4" :key="i" :height="'40px'" />
      </div>
      <div v-if="!wasEdit && !isLoading && (post.postType !== 'sold' || bidsList.length)" class="auction__bids-wrapper">
        <div class="auction__bid-title">Your bids activity for this post</div>
        <div class="auction__bids-inner">
          <div v-for="bidItem of bidsList" :key="bidItem.id" class="auction__bid">
            <div class="auction__bid-date">{{ moment(bidItem.createdAt).format('DD MMM HH:mm') }}</div>
            <div class="auction__bid-price">$ {{ bidItem.bid }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import moment from 'moment'
import Popup from '../../AuctionPopup'
import Skeleton from '@/components/Skeleton'

export default {
  name: 'Auction',
  components: {Skeleton},
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment,
      bid: null,
      postID: null,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      currentBid: s => s.community.auction.currentBid,
      bidsList: s => s.community.auction.bidsList,
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.post.authorID === this.userID
    }
  },
  async created() {
    this.postID = this.$route.params.id
    this.isLoading = true
    await Promise.all([this.fetch(this.postID), this.fetchBids(this.postID)])
    this.isLoading = false
    this.isLoad = true
  },
  methods: {
    ...mapActions({
      fetch: 'community/auction/fetchCurrentBid',
      fetchBids: 'community/auction/fetchBids',
      makeBid: 'community/auction/makeBid',
      markAsSold: 'community/posts/markAsSold'
    }),
    ...mapMutations({
      markAsSoldPost: 'community/posts/markAsSoldPost'
    }),
    async placeBid() {
      if (this.isLoading) return
      if (this.bid <= this.currentBid) return
      await this.makeBid({id: this.postID, bid: this.bid})
      this.bid = null
      await this.fetch(this.postID)
      await this.fetchBids(this.postID)
      this.$notify({
        message: 'Your bid has been placed successfully!',
        position: 'bottom-right',
        iconClass: 'ri-checkbox-circle-fill',
        customClass: 'el-notification-bid',
        showClose: false
      })
    },
    viewBids() {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 'auto', maxHeight: 600}
      this.$modal.display(
        Popup,
        {post: this.post},
        {
          name: 'AuctionPopup',
          transition: 'none',
          overlayTransition: 'none',
          ...modalHeight,
          width: '800px',
          classes: 'modal modal-auction'
        }
      )
    },
    async sold() {
      if (!this.isLoad) return
      this.isLoad = false
      try {
        await this.markAsSold(this.post.id)
        this.markAsSoldPost()
        this.$emit('hideAuction')
      } finally {
        this.isLoad = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
