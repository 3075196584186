<template>
  <div class="page-wrapper">
    <div class="page-header"></div>
    <div class="page-inner" :class="{'page-inner--auction': isAuction}">
      <div class="post__wrapper">
        <button class="button button--rounded" @click="$router.back()">
          <i class="ri-arrow-go-back-line"></i>
          <span>Back to Posts</span>
        </button>
        <div class="post__inner">
          <div class="post__header-wrapper">
            <Skeleton v-if="isLoading" :height="'40px'" />
            <div v-else class="post__header-inner">
              <div class="post__header-mobile">
                <div class="post__header-mobile-top" :class="{'is-actions': wasEdit || isBishop}">
                  <div
                    class="post__type"
                    :class="{
                      'is-blue': post.postType === 'post',
                      'is-green': post.postType === 'question',
                      'is-red': post.postType === 'forSale',
                      'is-orange': post.postType === 'sold'
                    }"
                  >
                    <span v-if="post.postType === 'post'">General</span>
                    <span v-else-if="post.postType === 'question'">Question</span>
                    <span v-else-if="post.postType === 'forSale'">For Sale</span>
                    <span v-else-if="post.postType === 'sold'">Sold</span>
                  </div>
                  <div class="post__creator-date">
                    {{ moment(post.updatedAt).format('DD MMM HH:mm') }}
                  </div>
                  <el-dropdown v-if="wasEdit || isBishop" trigger="click" @command="action">
                    <i class="ri-more-fill"></i>
                    <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                      <el-dropdown-item v-if="wasEdit || isBishop" class="item--edit" command="Edit">
                        <i class="ri-pencil-fill"></i> Edit
                      </el-dropdown-item>
                      <el-dropdown-item v-if="isBishop" class="item--remove" command="Remove">
                        <i class="ri-delete-bin-7-fill"></i> Remove
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <div class="post__header-mobile-bottom">
                  <div class="post__avatar">
                    <span v-if="post.authorBusinessName">{{ post.authorBusinessName[0] }}</span>
                  </div>
                  <div class="post__creator">
                    <div class="post__creator-name">
                      <span>{{ post.authorBusinessName }}</span>
                      <span v-if="post.authorNickName"> ({{ post.authorNickName }})</span>
                    </div>
                    <div class="post__creator-fname">
                      <span v-if="post.authorFathersName">{{ post.authorFathersName }}’ </span>
                      <span>{{ post.authorFirstName }}</span>
                      <span> {{ post.authorMiddleInitial }}</span>
                      <span> {{ post.authorLastName }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="post__header-desktop" :class="{'is-actions': wasEdit || isBishop}">
                <div class="post__avatar">
                  <span v-if="post.authorBusinessName">{{ post.authorBusinessName[0] }}</span>
                </div>
                <div class="post__creator">
                  <div class="post__creator-name">
                    <span>{{ post.authorBusinessName }}</span>
                    <span v-if="post.authorNickName"> ({{ post.authorNickName }})</span>
                    <span v-if="post.authorFathersName">{{ post.authorFathersName }}’ </span>
                    <span>{{ post.authorFirstName }}</span>
                    <span> {{ post.authorMiddleInitial }}</span>
                    <span> {{ post.authorLastName }}</span>
                  </div>
                  <div class="post__creator-date">
                    {{ moment(post.updatedAt).format('DD MMM HH:mm') }}
                  </div>
                </div>
                <div
                  class="post__type"
                  :class="{
                    'is-blue': post.postType === 'post',
                    'is-green': post.postType === 'question',
                    'is-red': post.postType === 'forSale',
                    'is-orange': post.postType === 'sold'
                  }"
                >
                  <span v-if="post.postType === 'post'">General</span>
                  <span v-else-if="post.postType === 'question'">Question</span>
                  <span v-else-if="post.postType === 'forSale'">For Sale</span>
                  <span v-else-if="post.postType === 'sold'">Sold</span>
                </div>
                <el-dropdown v-if="wasEdit || isBishop" trigger="click" @command="action">
                  <i class="ri-more-fill"></i>
                  <el-dropdown-menu slot="dropdown" class="el-popper-custom el-popper--actions">
                    <el-dropdown-item v-if="contentType !== 'audio'" class="item--edit" command="Edit">
                      <i class="ri-pencil-fill"></i> Edit
                    </el-dropdown-item>
                    <el-dropdown-item class="item--remove" command="Remove">
                      <i class="ri-delete-bin-7-fill"></i> Remove
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
          <div class="post__main">
            <Skeleton v-if="isLoading" :height="'180px'" />
            <div v-else class="post__main-inner">
              <div v-if="!contentType || contentType === 'image'" class="post__text">{{ post.content }}</div>
              <div v-if="contentType === 'audio'" class="post__audio">
                <Player :src="post.attachmentPath" />
              </div>
              <div class="post__image">
                <el-image
                  v-if="contentType === 'image'"
                  :src="post.attachmentPath"
                  :preview-src-list="[post.attachmentPath]"
                  fit="cover"
                />
              </div>
            </div>
          </div>
        </div>
        <Comments v-if="!isMobile" :post="post" />
      </div>
      <Auction v-if="isAuction" :post="post" @hideAuction="hideAuction" />
      <Comments v-if="isMobile" :post="post" />
    </div>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import NewPost from '../NewPost'
import moment from 'moment'
import Player from '@/components/AudioPlayer'
import Auction from './Auction'
import Comments from './Comments'
import Skeleton from '@/components/Skeleton'

export default {
  name: 'PostPage',
  components: {Comments, Player, Auction, Skeleton},
  data() {
    return {
      isLoading: false,
      moment,
      text: null,
      contentType: null,
      isAuction: false,
      isMobile: false
    }
  },
  computed: {
    ...mapState({
      post: s => s.community.posts.post,
      isBishop: s => s.auth.user.communityBishop,
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.post.authorID === this.userID
    },
    path() {
      return this.$route.params.id
    }
  },
  async created() {
    window.scrollTo(0, 0)
    this.isMobile = document.documentElement.clientWidth <= 480
    const postID = this.$route.params.id
    this.isLoading = true
    await this.fetch(postID)
    this.isLoading = false
    this.showAuction()
    if (this.post.attachmentType) {
      const indexSymbol = this.post.attachmentType.indexOf('/')
      this.contentType = this.post.attachmentType.slice(0, indexSymbol)
    }
  },
  methods: {
    ...mapActions({
      fetch: 'community/posts/fetchPost',
      fetchPosts: 'community/posts/fetch',
      delete: 'community/posts/delete'
    }),
    ...mapMutations({
      reset: 'community/posts/reset',
      setPost: 'community/posts/setPost'
    }),
    async action(action) {
      const mobile = document.documentElement.clientWidth <= 480
      let modalHeight = mobile ? {height: '100%'} : {height: 600}
      if (action === 'Edit') {
        this.$modal.display(
          NewPost,
          {
            type: 'text',
            post: this.post,
            target: 'postPage'
          },
          {
            name: 'NewPostModal',
            transition: 'none',
            ...modalHeight,
            classes: 'modal modal-new-post'
          }
        )
      } else if (action === 'Remove') {
        await this.delete(this.post.id)
        this.reset()
        this.$router.push('/community')
        await this.fetchPosts()
      }
    },
    showAuction() {
      const postType = this.post?.postType
      if (postType === 'forSale' || (postType === 'sold' && this.post.soldFor)) this.isAuction = true
    },
    hideAuction() {
      this.isAuction = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
