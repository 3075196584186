<template>
  <div class="comment__wrapper" :class="{'is-highlight': comment.highlight}">
    <div class="comment__inner">
      <div class="comment__avatar">
        <span>{{ comment.authorBusinessName[0] }}</span>
      </div>
      <div class="comment__header">
        <div class="comment__creator">
          <span>{{ comment.authorBusinessName }} </span>
          <span v-if="comment.authorNickName">({{ comment.authorNickName }}) </span>
          <span v-if="comment.authorFathersName">{{ comment.authorFathersName }}’ </span>
          <span>{{ comment.authorFirstName }}</span>
          <span>{{ comment.authorMiddleInitial }} </span>
          <span>{{ comment.authorLastName }} </span>
        </div>
        <div class="comment__text">{{ comment.text }}</div>
      </div>
      <div></div>
      <div class="comment__footer">
        <div class="comment__footer-date">{{ moment(comment.updatedAt).format('DD MMM HH:mm') }}</div>
        <button v-if="wasEdit || isBishop" class="comment__footer-button" @click="remove(comment)">Remove</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'PostComment',
  props: {
    comment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      moment
    }
  },
  computed: {
    ...mapState({
      isBishop: s => s.auth.user.communityBishop,
      userID: s => s.auth.user.id
    }),
    wasEdit() {
      return this.comment.authorID === this.userID
    }
  },
  async created() {
    setTimeout(() => {
      this.comment.highlight = false
    }, 2000)
  },
  methods: {
    async remove() {
      this.$emit('removeComment', this.comment)
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
