var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment__wrapper",
      class: { "is-highlight": _vm.comment.highlight }
    },
    [
      _c("div", { staticClass: "comment__inner" }, [
        _c("div", { staticClass: "comment__avatar" }, [
          _c("span", [_vm._v(_vm._s(_vm.comment.authorBusinessName[0]))])
        ]),
        _c("div", { staticClass: "comment__header" }, [
          _c("div", { staticClass: "comment__creator" }, [
            _c("span", [_vm._v(_vm._s(_vm.comment.authorBusinessName) + " ")]),
            _vm.comment.authorNickName
              ? _c("span", [
                  _vm._v("(" + _vm._s(_vm.comment.authorNickName) + ") ")
                ])
              : _vm._e(),
            _vm.comment.authorFathersName
              ? _c("span", [
                  _vm._v(_vm._s(_vm.comment.authorFathersName) + "’ ")
                ])
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.comment.authorFirstName))]),
            _c("span", [_vm._v(_vm._s(_vm.comment.authorMiddleInitial) + " ")]),
            _c("span", [_vm._v(_vm._s(_vm.comment.authorLastName) + " ")])
          ]),
          _c("div", { staticClass: "comment__text" }, [
            _vm._v(_vm._s(_vm.comment.text))
          ])
        ]),
        _c("div"),
        _c("div", { staticClass: "comment__footer" }, [
          _c("div", { staticClass: "comment__footer-date" }, [
            _vm._v(
              _vm._s(_vm.moment(_vm.comment.updatedAt).format("DD MMM HH:mm"))
            )
          ]),
          _vm.wasEdit || _vm.isBishop
            ? _c(
                "button",
                {
                  staticClass: "comment__footer-button",
                  on: {
                    click: function($event) {
                      return _vm.remove(_vm.comment)
                    }
                  }
                },
                [_vm._v("Remove")]
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }