<template>
  <div class="comments__wrapper">
    <div v-if="comments.length" class="comments__header">
      <div class="comments__counter">{{ pagination.total }} Comments</div>
    </div>
    <div class="comments__main">
      <Comment v-for="comment in comments" :key="comment.id" :comment="comment" @removeComment="remove" />
    </div>
    <div class="comments__footer">
      <div class="comments__avatar">
        <span v-if="user.customer.businessName">{{ user.customer.businessName[0] }}</span>
      </div>
      <form class="comments__form" @submit.prevent="createComment">
        <input
          v-model.trim="text"
          type="text"
          class="comments__footer-input"
          placeholder="Press to write your comment!"
        />
        <button class="comments__footer-button" type="submit">Post</button>
      </form>
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="infLoad"></infinite-loading>
  </div>
</template>

<script>
import {mapState, mapActions, mapMutations} from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
import moment from 'moment'
import Pusher from 'pusher-js'
import Comment from './Comment'

export default {
  name: 'PostComments',
  components: {InfiniteLoading, Comment},
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      isLoad: true,
      moment,
      text: null,
      postID: null,
      pusher: null
    }
  },
  computed: {
    ...mapState({
      comments: s => s.community.comments.comments,
      pagination: s => s.community.comments.pagination,
      page: s => s.community.comments.page,
      isBishop: s => s.auth.user.communityBishop,
      userID: s => s.auth.user.id,
      user: s => s.auth.user
    }),
    hasMoreResults() {
      return this.comments.length < this.pagination.total && this.page > 1
    },
    wasEdit() {
      return this.post.authorID === this.userID
    }
  },
  async created() {
    this.postID = this.$route.params.id
    this.isLoading = true
    await this.fetch({id: this.postID})
    this.subscribe()
    this.isLoading = false
  },
  beforeDestroy() {
    this.reset()
    this.pusher.unsubscribe(`posts-${this.post.id}-comments`)
  },
  methods: {
    ...mapActions({
      fetch: 'community/comments/fetch',
      create: 'community/comments/create',
      update: 'community/comments/update',
      delete: 'community/comments/delete'
    }),
    ...mapMutations({
      reset: 'community/comments/reset',
      add: 'community/comments/add',
      removeComment: 'community/comments/remove'
    }),
    async remove(comment) {
      if (!this.isLoad) return
      this.isLoad = false
      try {
        await this.delete({id: comment.id, postID: comment.postID})
      } finally {
        this.isLoad = true
      }
    },
    async createComment() {
      if (!this.text) return
      if (!this.isLoad) return
      this.isLoad = false
      try {
        await this.create({comment: {text: this.text}, id: this.postID})
        this.text = null
      } finally {
        this.isLoad = true
      }
    },
    async infLoad() {
      this.isLoading = true
      try {
        await this.fetch({id: this.postID})
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    subscribe() {
      const pusherID = process.env.VUE_APP_PUSHER
      const postID = this.post.id
      const pusher = this.pusher = new Pusher(pusherID, {cluster: 'us2'})
      pusher.subscribe(`posts-${postID}-comments`)
      pusher.bind('post.added-comment', data => {
        this.add({...data, highlight: true})
        const Comments = document.querySelector('.comments__main')
        Comments.scrollTop = 0
      })
      pusher.bind('post.deleted-comment', data => {
        this.removeComment(data.id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
