var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper" }, [
    _c("div", { staticClass: "page-header" }),
    _c(
      "div",
      {
        staticClass: "page-inner",
        class: { "page-inner--auction": _vm.isAuction }
      },
      [
        _c(
          "div",
          { staticClass: "post__wrapper" },
          [
            _c(
              "button",
              {
                staticClass: "button button--rounded",
                on: {
                  click: function($event) {
                    return _vm.$router.back()
                  }
                }
              },
              [
                _c("i", { staticClass: "ri-arrow-go-back-line" }),
                _c("span", [_vm._v("Back to Posts")])
              ]
            ),
            _c("div", { staticClass: "post__inner" }, [
              _c(
                "div",
                { staticClass: "post__header-wrapper" },
                [
                  _vm.isLoading
                    ? _c("Skeleton", { attrs: { height: "40px" } })
                    : _c("div", { staticClass: "post__header-inner" }, [
                        _c("div", { staticClass: "post__header-mobile" }, [
                          _c(
                            "div",
                            {
                              staticClass: "post__header-mobile-top",
                              class: {
                                "is-actions": _vm.wasEdit || _vm.isBishop
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "post__type",
                                  class: {
                                    "is-blue": _vm.post.postType === "post",
                                    "is-green":
                                      _vm.post.postType === "question",
                                    "is-red": _vm.post.postType === "forSale",
                                    "is-orange": _vm.post.postType === "sold"
                                  }
                                },
                                [
                                  _vm.post.postType === "post"
                                    ? _c("span", [_vm._v("General")])
                                    : _vm.post.postType === "question"
                                    ? _c("span", [_vm._v("Question")])
                                    : _vm.post.postType === "forSale"
                                    ? _c("span", [_vm._v("For Sale")])
                                    : _vm.post.postType === "sold"
                                    ? _c("span", [_vm._v("Sold")])
                                    : _vm._e()
                                ]
                              ),
                              _c("div", { staticClass: "post__creator-date" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .moment(_vm.post.updatedAt)
                                        .format("DD MMM HH:mm")
                                    ) +
                                    " "
                                )
                              ]),
                              _vm.wasEdit || _vm.isBishop
                                ? _c(
                                    "el-dropdown",
                                    {
                                      attrs: { trigger: "click" },
                                      on: { command: _vm.action }
                                    },
                                    [
                                      _c("i", { staticClass: "ri-more-fill" }),
                                      _c(
                                        "el-dropdown-menu",
                                        {
                                          staticClass:
                                            "el-popper-custom el-popper--actions",
                                          attrs: { slot: "dropdown" },
                                          slot: "dropdown"
                                        },
                                        [
                                          _vm.wasEdit || _vm.isBishop
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  staticClass: "item--edit",
                                                  attrs: { command: "Edit" }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ri-pencil-fill"
                                                  }),
                                                  _vm._v(" Edit ")
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.isBishop
                                            ? _c(
                                                "el-dropdown-item",
                                                {
                                                  staticClass: "item--remove",
                                                  attrs: { command: "Remove" }
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "ri-delete-bin-7-fill"
                                                  }),
                                                  _vm._v(" Remove ")
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "post__header-mobile-bottom" },
                            [
                              _c("div", { staticClass: "post__avatar" }, [
                                _vm.post.authorBusinessName
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.post.authorBusinessName[0])
                                      )
                                    ])
                                  : _vm._e()
                              ]),
                              _c("div", { staticClass: "post__creator" }, [
                                _c(
                                  "div",
                                  { staticClass: "post__creator-name" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.post.authorBusinessName)
                                      )
                                    ]),
                                    _vm.post.authorNickName
                                      ? _c("span", [
                                          _vm._v(
                                            " (" +
                                              _vm._s(_vm.post.authorNickName) +
                                              ")"
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "post__creator-fname" },
                                  [
                                    _vm.post.authorFathersName
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.post.authorFathersName) +
                                              "’ "
                                          )
                                        ])
                                      : _vm._e(),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.post.authorFirstName))
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.post.authorMiddleInitial)
                                      )
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.post.authorLastName)
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "post__header-desktop",
                            class: { "is-actions": _vm.wasEdit || _vm.isBishop }
                          },
                          [
                            _c("div", { staticClass: "post__avatar" }, [
                              _vm.post.authorBusinessName
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.post.authorBusinessName[0])
                                    )
                                  ])
                                : _vm._e()
                            ]),
                            _c("div", { staticClass: "post__creator" }, [
                              _c("div", { staticClass: "post__creator-name" }, [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.post.authorBusinessName))
                                ]),
                                _vm.post.authorNickName
                                  ? _c("span", [
                                      _vm._v(
                                        " (" +
                                          _vm._s(_vm.post.authorNickName) +
                                          ")"
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.post.authorFathersName
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.post.authorFathersName) +
                                          "’ "
                                      )
                                    ])
                                  : _vm._e(),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.post.authorFirstName))
                                ]),
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(_vm.post.authorMiddleInitial)
                                  )
                                ]),
                                _c("span", [
                                  _vm._v(" " + _vm._s(_vm.post.authorLastName))
                                ])
                              ]),
                              _c("div", { staticClass: "post__creator-date" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm
                                        .moment(_vm.post.updatedAt)
                                        .format("DD MMM HH:mm")
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "post__type",
                                class: {
                                  "is-blue": _vm.post.postType === "post",
                                  "is-green": _vm.post.postType === "question",
                                  "is-red": _vm.post.postType === "forSale",
                                  "is-orange": _vm.post.postType === "sold"
                                }
                              },
                              [
                                _vm.post.postType === "post"
                                  ? _c("span", [_vm._v("General")])
                                  : _vm.post.postType === "question"
                                  ? _c("span", [_vm._v("Question")])
                                  : _vm.post.postType === "forSale"
                                  ? _c("span", [_vm._v("For Sale")])
                                  : _vm.post.postType === "sold"
                                  ? _c("span", [_vm._v("Sold")])
                                  : _vm._e()
                              ]
                            ),
                            _vm.wasEdit || _vm.isBishop
                              ? _c(
                                  "el-dropdown",
                                  {
                                    attrs: { trigger: "click" },
                                    on: { command: _vm.action }
                                  },
                                  [
                                    _c("i", { staticClass: "ri-more-fill" }),
                                    _c(
                                      "el-dropdown-menu",
                                      {
                                        staticClass:
                                          "el-popper-custom el-popper--actions",
                                        attrs: { slot: "dropdown" },
                                        slot: "dropdown"
                                      },
                                      [
                                        _vm.contentType !== "audio"
                                          ? _c(
                                              "el-dropdown-item",
                                              {
                                                staticClass: "item--edit",
                                                attrs: { command: "Edit" }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "ri-pencil-fill"
                                                }),
                                                _vm._v(" Edit ")
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "el-dropdown-item",
                                          {
                                            staticClass: "item--remove",
                                            attrs: { command: "Remove" }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ri-delete-bin-7-fill"
                                            }),
                                            _vm._v(" Remove ")
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "post__main" },
                [
                  _vm.isLoading
                    ? _c("Skeleton", { attrs: { height: "180px" } })
                    : _c("div", { staticClass: "post__main-inner" }, [
                        !_vm.contentType || _vm.contentType === "image"
                          ? _c("div", { staticClass: "post__text" }, [
                              _vm._v(_vm._s(_vm.post.content))
                            ])
                          : _vm._e(),
                        _vm.contentType === "audio"
                          ? _c(
                              "div",
                              { staticClass: "post__audio" },
                              [
                                _c("Player", {
                                  attrs: { src: _vm.post.attachmentPath }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "post__image" },
                          [
                            _vm.contentType === "image"
                              ? _c("el-image", {
                                  attrs: {
                                    src: _vm.post.attachmentPath,
                                    "preview-src-list": [
                                      _vm.post.attachmentPath
                                    ],
                                    fit: "cover"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                ],
                1
              )
            ]),
            !_vm.isMobile
              ? _c("Comments", { attrs: { post: _vm.post } })
              : _vm._e()
          ],
          1
        ),
        _vm.isAuction
          ? _c("Auction", {
              attrs: { post: _vm.post },
              on: { hideAuction: _vm.hideAuction }
            })
          : _vm._e(),
        _vm.isMobile ? _c("Comments", { attrs: { post: _vm.post } }) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }