var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auction__wrapper" }, [
    _c("div", { staticClass: "auction__inner" }, [
      _c(
        "div",
        { staticClass: "auction__price-wrapper" },
        [
          _vm.isLoading
            ? _c("Skeleton", {
                staticStyle: { padding: "16px" },
                attrs: { height: "80px" }
              })
            : _c(
                "div",
                {
                  staticClass: "auction__price-inner",
                  class: { "is-orange": _vm.post.postType === "sold" }
                },
                [
                  _vm.post.postType === "forSale"
                    ? _c("span", { staticClass: "auction__price-title" }, [
                        _vm._v("Current bid")
                      ])
                    : _vm._e(),
                  _c("span", { staticClass: "auction__price-number" }, [
                    _vm._v("$ " + _vm._s(_vm.currentBid))
                  ])
                ]
              )
        ],
        1
      ),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "auction__form-skeleton" },
            _vm._l(2, function(i) {
              return _c("Skeleton", { key: i, attrs: { height: "40px" } })
            }),
            1
          )
        : _vm._e(),
      !_vm.wasEdit && !_vm.isLoading && _vm.post.postType !== "sold"
        ? _c(
            "form",
            {
              staticClass: "auction__form",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.placeBid($event)
                }
              }
            },
            [
              _c("div", { staticClass: "auction__form-title" }, [
                _vm._v("Place your bid")
              ]),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.bid,
                    expression: "bid",
                    modifiers: { trim: true }
                  },
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "##########",
                    expression: "'##########'"
                  }
                ],
                staticClass: "field__input",
                attrs: { type: "text", placeholder: "Your bid price" },
                domProps: { value: _vm.bid },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.bid = $event.target.value.trim()
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _c(
                "button",
                {
                  staticClass: "button button--fill",
                  attrs: { type: "submit" }
                },
                [_vm._v("Place bid")]
              )
            ]
          )
        : _vm._e(),
      _vm.wasEdit && !_vm.isLoading
        ? _c("div", { staticClass: "auction__buttons" }, [
            (_vm.post.postType === "sold" && _vm.post.soldFor) ||
            (_vm.post.postType === "forSale" && _vm.post.currentBid)
              ? _c(
                  "button",
                  {
                    staticClass: "button button--fill",
                    on: { click: _vm.viewBids }
                  },
                  [_vm._v(" View Bids Activity ")]
                )
              : _vm._e(),
            _vm.post.postType !== "sold"
              ? _c(
                  "button",
                  {
                    staticClass: "button button--orange",
                    on: { click: _vm.sold }
                  },
                  [_vm._v("Mark as Sold")]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "auction__bids-skeleton" },
            _vm._l(4, function(i) {
              return _c("Skeleton", { key: i, attrs: { height: "40px" } })
            }),
            1
          )
        : _vm._e(),
      !_vm.wasEdit &&
      !_vm.isLoading &&
      (_vm.post.postType !== "sold" || _vm.bidsList.length)
        ? _c("div", { staticClass: "auction__bids-wrapper" }, [
            _c("div", { staticClass: "auction__bid-title" }, [
              _vm._v("Your bids activity for this post")
            ]),
            _c(
              "div",
              { staticClass: "auction__bids-inner" },
              _vm._l(_vm.bidsList, function(bidItem) {
                return _c(
                  "div",
                  { key: bidItem.id, staticClass: "auction__bid" },
                  [
                    _c("div", { staticClass: "auction__bid-date" }, [
                      _vm._v(
                        _vm._s(
                          _vm.moment(bidItem.createdAt).format("DD MMM HH:mm")
                        )
                      )
                    ]),
                    _c("div", { staticClass: "auction__bid-price" }, [
                      _vm._v("$ " + _vm._s(bidItem.bid))
                    ])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }