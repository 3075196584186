var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "comments__wrapper" },
    [
      _vm.comments.length
        ? _c("div", { staticClass: "comments__header" }, [
            _c("div", { staticClass: "comments__counter" }, [
              _vm._v(_vm._s(_vm.pagination.total) + " Comments")
            ])
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "comments__main" },
        _vm._l(_vm.comments, function(comment) {
          return _c("Comment", {
            key: comment.id,
            attrs: { comment: comment },
            on: { removeComment: _vm.remove }
          })
        }),
        1
      ),
      _c("div", { staticClass: "comments__footer" }, [
        _c("div", { staticClass: "comments__avatar" }, [
          _vm.user.customer.businessName
            ? _c("span", [_vm._v(_vm._s(_vm.user.customer.businessName[0]))])
            : _vm._e()
        ]),
        _c(
          "form",
          {
            staticClass: "comments__form",
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.createComment($event)
              }
            }
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.text,
                  expression: "text",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "comments__footer-input",
              attrs: {
                type: "text",
                placeholder: "Press to write your comment!"
              },
              domProps: { value: _vm.text },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _c(
              "button",
              {
                staticClass: "comments__footer-button",
                attrs: { type: "submit" }
              },
              [_vm._v("Post")]
            )
          ]
        )
      ]),
      !_vm.isLoading && _vm.hasMoreResults
        ? _c("infinite-loading", { on: { infinite: _vm.infLoad } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }